<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'App',
    components: {},
    data(){
      return {

      }
    },
  }
</script>
<script></script>

<style>
</style>
